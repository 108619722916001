import { base64URLEncode, sha256 } from "./sha256-base64-url-encode"
import createCodeVerifier from './create-code-verifier'
import hashed from './hashed'
import getEncodedVerifierKey from './getEncodedVerifierKey'

export default function authorize({provider, clientId, storage = sessionStorage}) {

  const encodedVerifier = base64URLEncode(createCodeVerifier())
  storage.setItem(
    getEncodedVerifierKey(clientId),
    encodedVerifier
  )


 const challengeCode = base64URLEncode(sha256(encodedVerifier));

  console.log("challengeCode");
  console.log(challengeCode);

  let query = {
    client_id: clientId,
    response_type: 'code',
    redirect_uri: 'https://loginapp.tdaburger.com/protected',//window.location,
    nonce:'defaultNonce',
    scope: 'openid offline_access',
    prompt:'login',
    code_challenge: challengeCode,
    code_challenge_method: 'S256',
  }
  if(sessionStorage.getItem('loginhints')){
      const strJson  = sessionStorage.getItem('loginhints');
      const resultSet = JSON.parse(strJson);
      if(resultSet.loginType === "mobile"){
          query.loginType = resultSet.loginType;
          query.countryCode = resultSet.countryCode;
          query.nationalNumber = resultSet.nationalNumber;
          query.username = resultSet.username;
      }
      else if(resultSet.loginType === "username"){
          query.loginType = resultSet.loginType;
          query.username = resultSet.username;
      }
    //query.login_hint = sessionStorage.getItem('loginhints');
  }


  
  const url = `${ provider }/authorize?${ hashed(query) }`


  console.log("experience URL>>>>>>>");

   console.log(url);

  window.location.replace(url)
}
