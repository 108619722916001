import createAuthContext from './lib/createAuthContext'

const clientId = "ce79b320-8c7a-4419-958b-f6663685936e"
const clientSecret = "";//"1eeb36df-dfff-47b8-b0fb-437870fb9c3b"

//New Policy New Journey
//B2C_1A_PLUS_LOGIN_signin

//Old Policy - new journey
//B2C_1A_PLUS_LOGIN

const provider = "https://tdaburger.b2clogin.com/tdaburger.onmicrosoft.com/B2C_1A_PLUS_LOGIN/oauth2/v2.0"
const tokenEndpoint = "https://tdaburger.b2clogin.com/tdaburger.onmicrosoft.com/B2C_1A_PLUS_LOGIN/oauth2/v2.0/token"






export const {AuthContext, Authenticated, useToken} = createAuthContext({
  clientId,
  clientSecret,
  provider
})
