import React, { useState } from 'react';

function PlusRegisterPage() {
  const [userPrincipalName, setUserPrincipalName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [ssoId, setSsoId] = useState('');
  const [mobileNubmer, setMobileNubmer] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [surname, setSurname] = useState('');
  const [givenName, setGivenName] = useState('');

  const [ccId, setCcId] = useState('');
  const [ebId, setEbId] = useState('');
  const [vtId, setVtId] = useState('');
  const [ptId, setPtId] = useState('');
  
  const [lpId, setLpId] = useState('');
  const [leId, setLeId] = useState('');
  const [ccRegdate, setCcRegdate] = useState('');
  const [ebRegdate, setEbRegdate] = useState('');
  const [vtRegdate, setVtRegdate] = useState('');
  const [ptRegdate, setPtRegdate] = useState('');
  const [loginType, setLoginType] = useState('username');



  const countryCodes = [
    { name: 'Hong Kong', code: '+852', dialingCode: '+852' },
    { name: 'Macau', code: '+853', dialingCode: '+853' },
    { name: 'China', code: '+86', dialingCode: '+86' },
    { name: 'Afghanistan', code: '+93', dialingCode: '+93' },
    { name: 'Albania', code: '+355', dialingCode: '+355' },
    { name: 'Algeria', code: '+213', dialingCode: '+213' }
    // Add more country codes here...
  ];



  const [selectedCountryCode, setSelectedCountryCode] = useState('');


  const getDialingCode = (countryCode) => {
    const country = countryCodes.find((c) => c.code === countryCode);
    if (country) {
      return country.dialingCode;
    }
    return '';
  };


  const handleCountryCodeChange = (e) => {
    setSelectedCountryCode(e.target.value);
  };

  const generateRandomBufferForUsername = (length) => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz012345678';
  
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }

    return password;
  }

  const handleLoginTypeChange = (e) => {
    setLoginType(e.target.value);
  };

  const generateRandomPassword = (length) => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
  
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }
  

    return password;
  }
  
  const generateRandomUsername = () => {
    const adjectives = ['happy', 'clever', 'brave', 'sunny', 'vibrant', 'playful'];
    const nouns = ['Michelangelo', 'Leonardo', 'AprilONeil', 'Karai', 'Splinter', 'CaseyJones'];
    

    const randomAdjectiveIndex = Math.floor(Math.random() * adjectives.length);
    const randomNounIndex = Math.floor(Math.random() * nouns.length);
  
    const adjective = adjectives[randomAdjectiveIndex];
    const noun = nouns[randomNounIndex];
  
    const username = adjective + '' + noun + generateRandomBufferForUsername(5);
  
    return username;
  }
  
  const generateRandomHKMobileNumber = () => {
    const prefix = '6';
    const length = 8;
    
    let number = prefix;
    for (let i = 0; i < length; i++) {
      number += Math.floor(Math.random() * 10);
    }
    
    return number;
  }
  


  const handleGenerateMobileNumber = (e) => {
    const randomHKMobileNumber = generateRandomHKMobileNumber();
    console.log(randomHKMobileNumber);
    setMobileNubmer(randomHKMobileNumber)
}


  const handleGeneratePassword = (e) => {
    // Example usage: generate a random password with a length of 12
    const randomPassword = generateRandomPassword(12);
    setPassword(randomPassword)
}


  const handleGenerateUsername = (e) => {
    // Example usage: generate a random password with a length of 12
    const randomUsername = generateRandomUsername();
    setUserPrincipalName(randomUsername);
    setDisplayName(randomUsername);
    setSsoId(randomUsername);


    setEmail(randomUsername + "@gmail.com");
    setSurname(randomUsername +" Lau");
    setGivenName(randomUsername+ " Michael");
    
}


  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the form data
    // const formData = new URLSearchParams();
    // formData.append('userPrincipalName', userPrincipalName);
    // formData.append('displayName', displayName);
    // formData.append('password', password);
    // formData.append('ssoId', ssoId);
    // formData.append('mobilePhone', mobileNubmer);
    // formData.append('email', email);
    // formData.append('surname', surname);
    // formData.append('givenName', givenName);

    const formData = {
      'loginType':loginType,
      'userPrincipalName':userPrincipalName,
      'displayName':displayName,
      'password':password,
      'ssoId':ssoId,
      mobilePhone: mobileNubmer,
      email,
      surname,
      givenName,
      'countryCode':selectedCountryCode,
      'dialingCountryCode':getDialingCode(selectedCountryCode),
      'ccId':ccId,
      'ebId':ebId,
      'vtId':vtId,
      'lpId':lpId,
      'leId':leId,
      'CCRegdate':ccRegdate,
      'EBRegdate':ebRegdate,
      'VTRegdate':vtRegdate,
      'PTRegdate':ptRegdate
    };

  

    // Send the request to the API
    fetch('https://callbackserver.tdaburger.com/create-user', {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded'
        'Content-Type': 'application/json' // Update the Content-Type header

      },
      //body: formData.toString()
      body: JSON.stringify(formData) // Convert the form data object to a JSON string
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if(data.data.result==='success'){
          alert('User Created Successfully');
        }else{
          alert(`User Creation Failed: ${data.data.result}`);
        }

    
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });
  };

  return (
    <div style={{ padding: 20 }}>
      <h1>This is a Plus  Register Form</h1>
      <form onSubmit={handleSubmit}>


      <div>
      <label htmlFor="loginType">Login Type:</label>
      <select
        id="loginType"
        value={loginType}
        onChange={handleLoginTypeChange}
      >
        <option value="username">Username</option>
        <option value="mobile">Mobile</option>
      </select>
    </div>


{loginType==='username' &&(
        <div>
        <label htmlFor="ssoId">ssoId:</label>
        <input
          type="text"
          id="ssoId"
          value={ssoId}
          onChange={(e) => setSsoId(e.target.value)}
        />
          <span> {ssoId}</span>
      </div>
)}
        


      <div>
      <label htmlFor="countryCode">Country Code:</label>
      <select
        id="countryCode"
        value={selectedCountryCode}
        onChange={handleCountryCodeChange}
      >
        <option value="">-- Select Country --</option>
        {countryCodes.map((country) => (
          <option value={country.code} key={country.code}>
            {country.name}
          </option>
        ))}
      </select>
      {selectedCountryCode && (
        <div>
          Selected Country Code: {selectedCountryCode}
          <br />
          Dialing Code: {getDialingCode(selectedCountryCode)}
        </div>
      )}
    </div>






        <div>
          <label htmlFor="mobileNubmer">mobileNubmer:</label>
          <input
            type="text"
            id="mobileNubmer"
            value={mobileNubmer}
            onChange={(e) => setMobileNubmer(e.target.value)}
          />
            <span> {mobileNubmer}</span>
        </div>

        <div>
          <label htmlFor="email">email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
            <span> {email}</span>
        </div>



        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span> {password}</span>
        </div>


        <div>
          <label htmlFor="ccId">CC ID:</label>
          <input
            type="text"
            id="ccId"
            value={ccId}
            onChange={(e) => setCcId(e.target.value)}
          />
            <span>Sample Value: 268869982</span>
        </div>


        <div>
          <label htmlFor="ccId">EB ID:</label>
          <input
            type="text"
            id="ebId"
            value={ebId}
            onChange={(e) => setEbId(e.target.value)}
          />
            <span>Sample Value: AIAC_1691212102212</span>
        </div>


        <div>
          <label htmlFor="vtId">VT ID:</label>
          <input
            type="text"
            id="vtId"
            value={vtId}
            onChange={(e) => setVtId(e.target.value)}
          />
            <span>Sample Value: V800042404</span>
        </div>


        <div>
          <label htmlFor="ptId">PT ID:</label>
          <input
            type="text"
            id="ptId"
            value={ptId}
            onChange={(e) => setPtId(e.target.value)}
          />
            <span>Sample Value: 011835608</span>
        </div>

        <div>
          <label htmlFor="leId">LE ID:</label>
          <input
            type="text"
            id="leId"
            value={leId}
            onChange={(e) => setLeId(e.target.value)}
          />
            <span>Sample Value: test293</span>
        </div>


        <div>
          <label htmlFor="ccRegdate">CC Register Date (CCRegdate):</label>
          <input
            type="text"
            id="ccRegdate"
            value={ccRegdate}
            onChange={(e) => setCcRegdate(e.target.value)}
          />
            <span>Sample Value: Jul 12, 2019, 1:14:06 PM</span>
        </div>

        <div>
          <label htmlFor="ebRegdate">EB Register Date (EBRegdate):</label>
          <input
            type="text"
            id="ebRegdate"
            value={ebRegdate}
            onChange={(e) => setEbRegdate(e.target.value)}
          />
            <span>Sample Value: Jul 12, 2019, 1:14:06 PM</span>
        </div>


        <div>
          <label htmlFor="vtRegdate">VT Register Date (VTRegdate):</label>
          <input
            type="text"
            id="vtRegdate"
            value={vtRegdate}
            onChange={(e) => setVtRegdate(e.target.value)}
          />
            <span>Sample Value: Jul 12, 2019, 1:14:06 PM</span>
        </div>



        <div>
          <label htmlFor="ptRegdate">PT Register Date (PTRegdate):</label>
          <input
            type="text"
            id="ptRegdate"
            value={ptRegdate}
            onChange={(e) => setPtRegdate(e.target.value)}
          />
            <span>Sample Value: Jul 12, 2019, 1:14:06 PM</span>
        </div>




        



        <button type="submit">Submit</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button type="button" onClick={handleGeneratePassword}>Generate Password</button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button type="button" onClick={handleGenerateUsername}>Generate Username</button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button type="button" onClick={handleGenerateMobileNumber}>Generate Mobile Number</button>
      </form>
    </div>
  );
}

export default PlusRegisterPage;