import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AuthContext, Authenticated } from './auth'
import Protected from './Protected'

import RegisterPage from './RegisterPage'
import PlusRegisterPage from './PlusRegisterPage'



const basename = window.location.href ? '/react-pkce-sample' : undefined
//const basename = window.location.href 


function App() {


  const handleClickWithLoginTypeMobile = () => {
    const json =  {
      loginType:"mobile",
      countryCode:"+852",
      nationalNumber:"65368584",
      username:"85265368584"
    };
    sessionStorage.setItem('loginhints', JSON.stringify(json));
  };


  const handleClickWithLoginTypeUsername = () => {
    const json =  {
      loginType:"username",
      username:"85265368584"
    };
    sessionStorage.setItem('loginhints', JSON.stringify(json));

  };


  return (
    <div style={{ padding: 20, background:'#ae9292' }}>
      <h1>TDA Burger Login Journey - v2.0.20230927_19_48</h1>
      <AuthContext>
        <div>
        <a id="home" className="menu-item" href="/">Home</a> &nbsp;&nbsp;
          <a  id="home" className="menu-item" href="/protected">Login now!!! </a>&nbsp;&nbsp;&nbsp;&nbsp;

          <a onClick={handleClickWithLoginTypeMobile} id="home" className="menu-item" href="/protected">Login with Login Type Mobile</a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a onClick={handleClickWithLoginTypeUsername} id="home" className="menu-item" href="/protected">Login with Login Type Username</a>&nbsp;&nbsp;&nbsp;&nbsp;




        </div>
        <BrowserRouter>
          <Routes>



          <Route path="/" element={<div >This is ADB2C Demo</div>} />
            <Route path="/protected" element={<Authenticated>
              <Protected />
            </Authenticated>} />

            <Route path="/register" element={<RegisterPage />}/>
            <Route path="/plus-register" element={<PlusRegisterPage />}/>


            



          </Routes>
        </BrowserRouter>
      </AuthContext>
    </div>
  );
}

export default App;
